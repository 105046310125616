<script>
import InformationsSinglePage from '@/components/InformationsSinglePage';
import api from '@/services/api';
import { BButton } from 'bootstrap-vue';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
export default {
    name: 'Prizes',
    components: { ProgressLinear, InformationsSinglePage, BButton },
    data: () => ({
        prizes: {},
        requestPending: true
    }),
    methods: {
        async getPrizes() {
            this.requestPending = true;
            const { data } = await api.prizes.get();
            this.prizes = data;
            this.requestPending = false;
        },

        async updatePrizes() {
            await api.prizes
                .put(this.prizes.id, this.prizes)
                .then(() => {
                    this.$toast.success(this.$t('success.updated.title'));
                })
                .catch(() => {
                    this.$toast.error(this.$t('error.smth'));
                });
        }
    },
    mounted() {
        this.getPrizes();
    }
};
</script>

<template>
    <div>
        <h1 class="page-title mb-2">Prizes</h1>
        <progress-linear v-if="requestPending" />
        <informations-single-page v-else v-model="prizes">
            <template #button>
                <b-button
                    v-if="hasAccess('prizes', 'update')"
                    variant="success"
                    class="float-right"
                    @click="updatePrizes"
                >
                    {{ $t('button.save') }}
                </b-button>
            </template>
        </informations-single-page>
    </div>
</template>
